import Controller from 'core/controllers/paginated-controller';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export default class CloudOrgsDetailProjectsListController extends Controller {
  queryParams = ['pageSize'];

  @tracked pageSize = 10;

  @action
  onPageSizeChange(pageSize) {
    this.pageSize = pageSize;
  }
}
