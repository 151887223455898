/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { toCanvas } from 'qrcode';
import { action } from '@ember/object';
import * as Sentry from '@sentry/ember';
import { task, dropTask } from 'ember-concurrency';
import {
  FieldViolation,
  FormValidationError,
} from 'core/utils/form-validation';

/**
 *
 * `PageAccountSettingsEnableMFAModalTwoComponent` renders the second modal in the MFA enable flow.
 *
 * This component is primarily responsible for:
 *  1. Rendering a QR code asynchronously (see renderMFAQRCode method below)
 *  2. One-Time Password(OTP) validation (see confirmOTP method below).
 *
 * ```
 * <Page::AccountSettings::Security::Mfa::Enable::ModalTwo
        @setModalStep={{this.setNextEnableMFAModalStep}}
        @onReset={{this.resetMFAModal}}
        @onError={{this.handleMFAError}}
    />
 * ```
 *
 * @class PageAccountSettingsMFAModalTwo
 *
 * `@setModalStep` calls `setNextEnableMFAModalStep()`(see `PageAccountSettingsMFAComponent`) that updates
 * the `mode` of `PageAccountSettingsMFAComponent` to `'Recovery Code'` which will display the
 * next/last step of the MFA Enable flow(see `PageAccountSettingsMFAModalThree`)
 * @argument setModalStep;
 * @type {Function};
 *
 * `@onReset` calls the `resetMFAModal()` method that transitions the user to the Security index
 * route (see PageAccountSettingsMFAComponent).
 * @argument onReset;
 * @type {Function};
 * @param {boolean} [mfaEnrollmentIsSuccessful] - `mfaEnrollmentIsSuccessful` is an optional parameter that will trigger a success flashMessage
 * indicating successful MFA enablement. If not passed in, the default value of `false` will be used instead. For `PageAccountSettingsMFAModalTwo` at this time, **this param should not be passed in** since there's
 * no way to determine successful completion of the MFA Enable flow at this step.
 *
 * `@onError` calls `handleMFAError()` in `PageAccountSettingsMFAComponent` that returns an error flashMessage
 * @argument onError;
 * @type {Function};
 * @param {string} [errorMsg] - `errorMsg` is an optional parameter for custom error messaging; **if not included**, the error string will be set to a default error string;
 *
 */

export default class PageAccountSettingsEnableMFAModalTwoComponent extends Component {
  constructor() {
    super(...arguments);
    this.enableMFA.perform();
  }

  @service api;
  @service intl;
  @service flashMessages;
  @service mfa;

  @tracked error = '';
  @tracked userOTP = '';
  @tracked userOTPIsInvalid = false;

  // doing api calls in non-page components is a bit of an anti-pattern, but
  // here it's a bit nicer because we can show a spinner in the modal while it
  // happens instead of doing this in the callback route and the user just
  // seeing the application loading screen
  @dropTask
  *enableMFA() {
    let response = yield this.api.profile.profileServiceEnableMFA({
      mfaToken: this.mfa.mfaToken,
    });
    this.mfa.enableMFAResponse = response;
  }

  @action
  async renderMFAQRCode(element) {
    let qr = this.mfa.enableMFAResponse.qrcodeUri;
    await toCanvas(element, qr, { width: 248 });
  }

  handleConfirmOTPError(error, response) {
    if (response.status === 403) {
      let badOTP = error.message.includes('Invalid otp_code');
      let invalidMFAToken = error.message.includes('mfa_token is expired');
      this.error = this.intl.t(
        'components.page.account-settings.security.mfaModal.step2.invalidError'
      );
      if (badOTP) {
        this.userOTPIsInvalid = true;
      } else if (invalidMFAToken) {
        this.args.onError(
          'components.page.account-settings.security.mfaEnable.flashMessage.errorTimeout'
        );
        this.args.onReset();
        Sentry.captureException(error.message);
      }
    } else {
      this.args.onReset();
      this.args.onError();
    }
  }

  @action
  preventEmptySubmission() {
    const validationError = new FormValidationError(
      this.intl.t(
        'components.page.account-settings.security.mfaModal.step2.empty-error'
      )
    );
    if (this.userOTP === undefined || this.userOTP === '') {
      validationError.details.push(
        new FieldViolation(
          this.intl.t(
            'components.page.account-settings.security.mfaModal.step2.empty-error'
          ),
          'otp-input'
        )
      );
    }
    if (validationError.details.length) {
      this.userOTPIsInvalid = true;
      this.error = validationError.details[0].field_violations[0].description;
      throw validationError;
    }
  }

  @task
  *confirmOTP(evt) {
    evt.preventDefault();
    this.preventEmptySubmission(this.userOTP);
    if (this.userOTP) {
      let confirmationOtp = this.userOTP;
      let mfaToken = this.mfa.mfaToken;
      try {
        yield this.api.profile.profileServiceConfirmMFAEnrollment({
          confirmationOtp,
          mfaToken,
        });
        if (this.userOTPIsInvalid) this.userOTPIsInvalid = false;
        this.args.setModalStep();
      } catch (response) {
        let error = yield response.json();
        this.handleConfirmOTPError(error, response);
      }
    }
  }
}
