/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { getOwner } from '@ember/owner';
import { action } from '@ember/object';
import auth0MFALoginRedirect from 'hcp/utils/auth0-mfa-login-redirect';

/**
 *
 * `PageAccountSettingsEnableMFAModalOne` renders the the first modal in the MFA enable flow.
 *
 * This component triggers a login via the Auth0 Universal Login widget while passing the mfa
 * flow ('enable') in `mfaState`, then redirects the user to the security route where an mfa token
 * is generated, the IAM client's `profileServiceEnableMFA` endpoint is called, and the user is redirected back
 * to the MFA page (see Callback router).
 *
 *
 * ```
 * <Page::AccountSettings::Security::Mfa::Enable::ModalOne
        @onReset={{this.resetMFAModal}}
    />
 * ```
 *
 * @class PageAccountSettingsMFAModalOne
 *
 * `@onReset` calls the `resetMFAModal()` method that transitions the user to the Security index
 * route (see PageAccountSettingsMFAComponent).
 * @argument onReset;
 * @type {Function};
 *
 */

export default class PageAccountSettingsEnableMFAModalOneComponent extends Component {
  authenticator = getOwner(this).lookup('authenticator:auth0');

  @action
  async redirectForMFAToken() {
    let client = await this.authenticator.client;
    auth0MFALoginRedirect(client, 'enable');
  }
}
