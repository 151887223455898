/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import {
  FieldViolation,
  FormValidationError,
} from 'core/utils/form-validation';

/**
 *
 * `PageAccountSettingsEnableMFAModalThreeComponent` renders the third/final modal in the MFA enable flow.
 *
 * This component is primarily responsible for:
 *  1. Displaying the user's MFA recovery code
 *  2. Confirming that the user has recorded their MFA recovery code
 *  3. Resetting the modal & displaying success flashMessage (note: Success is defined as any exit method of this modal)
 *
 * ```
 * <Page::AccountSettings::Security::Mfa::Enable::ModalThree
        @setModalStep={{this.setNextEnableMFAModalStep}}
        @onReset={{this.resetMFAModal}}
    />
 * ```
 *
 * @class PageAccountSettingsMFAModalThree
 *
 * `@setModalStep` calls `setNextEnableMFAModalStep()`(see `PageAccountSettingsMFAComponent`) which resets the
 * MFA modal (see `@onReset` functionality below), updates the `mode` of `PageAccountSettingsMFAComponent` to an
 * empty string, and displays a success flashMessage.
 * @argument setModalStep;
 * @type {Function};
 *
 * `@onReset` calls the `resetMFAModal()` method that transitions the user to the Security index
 * route (see PageAccountSettingsMFAComponent).
 * @argument onReset;
 * @type {Function};
 * @param {boolean} [mfaEnrollmentIsSuccessful] - `mfaEnrollmentIsSuccessful` is an optional parameter that will trigger
 * a success flashMessage indicating successful MFA enablement. If not passed in, the default value of `false` will be used instead.
 * `PageAccountSettingsMFAModalThree` **should always pass `true`** when calling `@onReset` since the user will have MFA successfully
 * enabled at this step.
 *
 */

export default class PageAccountSettingsEnableMFAModalThreeComponent extends Component {
  @service intl;
  @service mfa;
  @tracked recoveryCodeCheckbox = false;
  @tracked error = '';

  @action
  validateForm() {
    const validationError = new FormValidationError(
      this.intl.t(
        'components.page.account-settings.security.mfaModal.step3.error'
      )
    );
    if (this.recoveryCodeCheckbox === false) {
      validationError.details.push(
        new FieldViolation(
          this.intl.t(
            'components.page.account-settings.security.mfaModal.step3.error'
          ),
          'recovery-code-checkbox'
        )
      );
      if (validationError.details.length) {
        this.error = validationError.details[0].field_violations[0].description;
        throw validationError;
      }
    }
    this.args.onReset(true);
  }

  get mfaRecoveryCode() {
    let [recoveryCode] = this.mfa.enableMFAResponse.recoveryCodes;

    return recoveryCode;
  }
}
