/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';
import { action, set } from '@ember/object';
import { DEFAULT_USER_MODEL, USER_FORM } from './consts';

/**
 *
 * `PageUsersInvite` displays the create form for inviting a HCP User.
 *
 *
 * ```
 * <Page::Users::Invite @model={{model}} />
 * ```
 *
 * @class PageUsersInvite
 *
 */

/**
 * @argument model
 * @type {Object}
 */

export default class PageUsersInviteComponent extends Component {
  @service api;
  @service abilities;
  @service flashMessages;
  @service intl;
  @service router;

  @tracked isValidEmail = false;
  @tracked userForm = {
    ...USER_FORM,
  };
  @tracked user = {
    ...DEFAULT_USER_MODEL,
  };
  @tracked users = [];
  @tracked noUsersError = false;

  addUser(newUser) {
    let exists = this.users.find((user) => {
      return user.email === newUser.email;
    });

    if (exists) {
      return;
    }

    this.users = [...this.users, newUser];
    this.noUsersError = false;
  }

  checkElementValidity(element) {
    // Use the native form input to find validity.
    return element.checkValidity();
  }

  @action
  removeUser(user) {
    this.users = this.users.filter((item) => item.email !== user.email);
  }

  @action
  resetUserForm() {
    this.userForm = {
      ...USER_FORM,
    };

    return (this.user = {
      ...DEFAULT_USER_MODEL,
    });
  }

  @task
  *save(evt) {
    // don't submit the form
    evt.preventDefault();

    let orgId = this.args.model.organization.id;
    let { users } = this;
    if (users.length == 0) {
      this.noUsersError = true;
      return;
    }

    let payload = {
      invitations: users.map(({ email: inviteeEmail, role }) => {
        return { inviteeEmail, role };
      }),
    };

    yield this.api.invitation.invitationsServiceCreateOrganizationInvitations(
      orgId,
      payload
    );

    this.flashMessages.success(
      this.intl.t('components.page.access-control.users.invite.success.title', {
        numUsers: users.length,
      }),
      {
        content: this.intl.t(
          'components.page.access-control.users.invite.success.message'
        ),
      }
    );

    return this.router.transitionTo('cloud.access-control.users');
  }

  setValidForm(form, element) {
    set(form, 'isPristine', false);
    set(form, 'isValid', this.checkElementValidity(element));
    return form;
  }

  @action
  setValidUserEmail(evt) {
    return set(
      this.userForm,
      'isUserEmailValid',
      this.checkElementValidity(evt.target)
    );
  }

  @action
  submitUserForm(evt) {
    evt.preventDefault();
    if (this.user.email === '') {
      return;
    }
    let { isValid } = this.setValidForm(this.userForm, evt.srcElement);

    if (!isValid) {
      return;
    }

    this.addUser(this.user);

    return this.resetUserForm();
  }
}
