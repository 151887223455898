/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';

import { HashicorpCloudResourcemanagerResourceIDResourceType } from '@clients/cloud-resource-manager';

/**
 *
 * Create form for a new project.
 *
 *
 * ```
 * <Page::Projects::Create
 *   @organization={{this.model.organization}}
 *   @projects={{array project1 project2}}
 * />
 * ```
 *
 * @class PageProjectsCreate
 *
 */

export const MAX_UPDATE_ADD_PROJECT_TO_BILLING_RETRIES = 3;
export const MIN_PROJECT_NAME_LENGTH = 3;

/**
 * The current organization's projects list.
 * @argument projects
 * @type {Array}
 */

export default class PageProjectsCreateComponent extends Component {
  @service api;
  @service router;
  @service userContext;
  @service billing;
  @service flashMessages;
  @service intl;

  @tracked name = '';
  @tracked nameIsPristine = true;
  @tracked description = '';
  @tracked validationError;

  @task
  *save(evt) {
    // don't submit the form
    evt.preventDefault();

    this.nameIsPristine = false;

    yield this.validate.perform();

    if (this.validationError) {
      return;
    }

    const { name, description } = this;
    const { organization, projects } = this.args;

    const payload = {
      name,
      description,
      parent: {
        id: organization.id,
        type: HashicorpCloudResourcemanagerResourceIDResourceType.ORGANIZATION,
      },
    };

    const { billing } = this.userContext;
    const { project } =
      yield this.api.resourceManager.project.projectServiceCreate(payload);

    // We attach every project to the same billing account. This means that we
    // can use a previously created project to retrieve the billing id that is
    // associated at the organization level. We will use that billing account id
    // to attach the new project to that same billing account. This is why this
    // method is using the first project in the list of projects. Hopefully it
    // always has a billing account attached. In the future, since we use the
    // same account id for every project, we could potentially query by the
    // static name if we do not have any projects in the list.
    const { id: billingAccountId } = yield this.getBillingAccount.perform(
      organization,
      projects[0],
      billing
    );

    const { updated, response } = yield* this.addProjectToBillingAccount(
      organization.id,
      project.id,
      billingAccountId,
      MAX_UPDATE_ADD_PROJECT_TO_BILLING_RETRIES
    );

    // If we added the project to the billing account then update our
    // userContext with the response. Otherwise, delete the project and alert
    // the user that something went wrong. A project without a billing account
    // is not useful and this error needs to be resolved.
    if (updated) {
      this.userContext.billing = response;

      this.flashMessages.success(
        this.intl.t('components.page.projects.create.project-created'),
        {
          content: this.intl.t(
            'components.page.projects.create.project-has-been-created',
            {
              projectName: name,
            }
          ),
          actionText: this.intl.t(
            'components.page.projects.create.go-to-project'
          ),
          onAction: () => {
            return this.router.transitionTo(
              'cloud.orgs.detail.projects.detail',
              project.id
            );
          },
        }
      );
      this.router.transitionTo('cloud.orgs.detail.projects.list');
    } else {
      // Delete the project to clean up and then throw an error to trigger
      // the FormError component.
      yield this.api.resourceManager.project.projectServiceDelete(project.id);

      const cantCreateError = new Error();

      cantCreateError.message = this.intl.t(
        'components.page.projects.create.project-has-been-deleted-no-billing'
      );

      throw cantCreateError;
    }
  }

  @task
  *validate() {
    const { name } = this;
    const noSpecialCharactersRegex = /[^a-zA-Z0-9\s_\-.]/;

    try {
      const validationError = new Error();
      validationError.details = [];

      // Check that project name is not blank
      if (!name || !name.length) {
        validationError.details = [
          ...validationError.details,
          {
            field_violations: [
              {
                field: `name`,
                description: this.intl.t(
                  'components.page.projects.create.form.label.project-name-cannot-be-blank-error'
                ),
              },
            ],
          },
        ];
      }

      // Check that project name is greater than the set minimum length
      if (name && name.length < MIN_PROJECT_NAME_LENGTH) {
        validationError.details = [
          ...validationError.details,
          {
            field_violations: [
              {
                field: `name`,
                description: this.intl.t(
                  'components.page.projects.create.form.label.project-name-must-be-minimum-length-error'
                ),
              },
            ],
          },
        ];
      }

      // Check that project name does not contain disallowed special characters
      if (name && noSpecialCharactersRegex.test(name)) {
        validationError.details = [
          ...validationError.details,
          {
            field_violations: [
              {
                field: `name`,
                description: this.intl.t(
                  'components.page.projects.create.form.label.project-name-contains-disallowed-characters-error'
                ),
              },
            ],
          },
        ];
      }

      // Check that project name does not begin with a space
      if (name && name.startsWith(' ')) {
        validationError.details = [
          ...validationError.details,
          {
            field_violations: [
              {
                field: `name`,
                description: this.intl.t(
                  'components.page.projects.create.form.label.project-name-begins-with-space-error'
                ),
              },
            ],
          },
        ];
      }

      // Check that project name does not end with a space
      if (name && name.endsWith(' ')) {
        validationError.details = [
          ...validationError.details,
          {
            field_violations: [
              {
                field: `name`,
                description: this.intl.t(
                  'components.page.projects.create.form.label.project-name-ends-with-space-error'
                ),
              },
            ],
          },
        ];
      }

      if (validationError.details.length) {
        throw validationError;
      } else {
        yield (this.validationError = undefined);
      }
    } catch (e) {
      this.validationError = e;
    }
  }

  @task
  *getBillingAccount(organization, project, billing) {
    if (!organization?.id) {
      throw new Error('getBillingAccount requires an organization id');
    }

    if (!project.id) {
      throw new Error('getBillingAccount requires a project id');
    }

    if (!billing) {
      this.userContext.billing = yield this.billing.getBilling(
        organization.id,
        project.id
      );
    }

    return this.userContext.billing.billingAccount;
  }

  // addProjectToBillingAccount attempts to update the billing account with the
  // newly created project. Concurrently it will retry up to maxRetries.
  *addProjectToBillingAccount(orgId, projectId, billingAccountId, maxRetries) {
    for (let i = 0; i < maxRetries; i++) {
      try {
        const response = yield this.billing.addProjectToBillingAccount(
          orgId,
          projectId,
          billingAccountId
        );

        // If there's no billing account, throw an error so that we catch it and
        // try again. This response will always 200 but if there's no billing
        // account then it returns an empty Billing class.
        if (!response.billingAccount) {
          throw new Error();
        }

        return {
          updated: true,
          response,
        };
      } catch (e) {
        continue;
      }
    }

    return {
      updated: false,
    };
  }
}
