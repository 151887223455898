/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import { getOwner } from '@ember/owner';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { camelize } from '@ember/string';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';
import { variation } from 'ember-launch-darkly';

export default class PageOrganizationsDetailSsoSamlConfigureComponent extends Component {
  @service api;
  @service flashMessages;
  @service intl;
  @service router;

  @tracked domains = this.configuration?.emailDomains ?? [''];
  @tracked submittedDomains = [];

  get defaultRole() {
    const FALLBACK_DEFAULT_ROLE = variation(
      'hcp-identity-sso-default-role-empty-string-no-role'
    )
      ? 'none'
      : 'roles/viewer';

    return this.args.model.configs?.[0]?.defaultRole ?? FALLBACK_DEFAULT_ROLE;
  }

  get configuration() {
    return this.args.model.configs?.[0]?.saml;
  }

  get lastDomainIndex() {
    if (!this.domains.length) return 0;
    return this.domains.length - 1;
  }

  get emailDomains() {
    return this.domains.filter((domain) => domain !== '');
  }

  // if the email domain entered matches the domain already set, we can assume it's already been verified
  get unverifiedDomains() {
    return this.submittedDomains.filter((domain) => !domain.verified);
  }

  get verifiedDomains() {
    if (
      (this.configuration?.emailDomain && this.submittedDomains.length <= 0) ||
      (this.configuration?.emailDomains && this.submittedDomains.length <= 0)
    ) {
      return this.verifyExistingDomains();
    }

    return this.submittedDomains.filter((domain) => domain.verified);
  }

  // The domain must be converted from `example.com` to exampleCom because the
  // Ember template helper `get` confuses `example.com` for object dot notation.
  get unverifiedReasonsGroupedByDomain() {
    return this.unverifiedDomains.reduce((grouped, domain) => {
      return {
        ...grouped,
        [camelize(domain.domain)]: domain.reason,
      };
    }, {});
  }

  get verifiedReasonsGroupedByDomain() {
    return this.verifiedDomains.reduce((grouped, domain) => {
      return {
        ...grouped,
        [camelize(domain.domain)]: domain.reason,
      };
    }, {});
  }

  @action
  addDomain(index) {
    if (this.domains[index]) {
      this.domains = [...this.domains, ''];
    }
  }

  @action
  removeDomain(index) {
    let [removedDomain] = this.domains.splice(index, 1);
    this.domains = [...this.domains];
    this.submittedDomains = this.submittedDomains.filter(
      ({ domain }) => domain !== removedDomain
    );
  }

  @action
  updateDomain(index, evt) {
    this.domains[index] = evt.target.value;
    this.domains = [...this.domains];
    this.submittedDomains = this.submittedDomains.filter(({ domain }) =>
      this.domains.includes(domain)
    );
  }

  performVerificationRequest() {
    let organizationId = this.args.model.org.id;
    return Promise.all(
      this.emailDomains.map((domain) =>
        this.api.sso.sSOManagementServiceVerifyDomainOwnership(organizationId, {
          organizationId: this.args.model.org.id,
          domain,
        })
      )
    );
  }

  async verifyExistingDomains() {
    this.submittedDomains = await this.performVerificationRequest();
  }

  @task
  *verifyDomains(evt) {
    evt.preventDefault();
    this.submittedDomains = yield this.performVerificationRequest();
  }

  @task
  *save(body) {
    let messageType = 'created';
    let organizationId = this.args.model.org.id;

    if (this.configuration) {
      // When there's previous config info we know we're editing the form
      messageType = 'updated';
      yield this.api.sso.sSOManagementServiceUpdateSSOConfiguration(
        organizationId,
        'SAML',
        body
      );
    } else {
      yield this.api.sso.sSOManagementServiceCreateSSOConfiguration(
        organizationId,
        body
      );
    }

    let parent = getOwner(this).lookup(`route:cloud.orgs.detail.settings.sso`);

    yield parent.refresh();
    this.flashMessages.success(
      this.intl.t(
        `components.page.organizations.sso.configure.success.${messageType}`,
        {
          orgName: this.args.model.org.name,
        }
      )
    );
    yield this.router.transitionTo(
      'cloud.orgs.detail.settings.sso.index',
      organizationId
    );
  }
}
