import { ROLE_TYPE_CONTRIBUTOR } from 'core/helpers/rbac-roles';

export const DEFAULT_USER_MODEL = {
  email: '',
  role: ROLE_TYPE_CONTRIBUTOR.value,
};

export const USER_FORM = {
  isPristine: true,
  isUserEmailValid: false,
  isValid: false,
};
