import Service from '@ember/service';

/*
 *
 * The MFAService stores some state pertaining to the MFA enable flow.
 *
 * If a user is in the enable flow, the callback route will set the mfaToken from
 * auth0 on this service in the `mfaToken` attribute.
 *
 * The OTP modal then does the enable MFA call, and stores the response (detailed below)
 * as `enableMFAResponse` - this allows us to use the state from the service to
 * complete the OTP verification flow.
 *
 * @typedef EnableMFAResponse - `EnableMFAResponse` [proto definition](https://github.com/hashicorp/cloud-iam/blob/master/proto-staging/profile.proto#L66)
 * @property {string} qrcodeUri - an otpauth qr code uri string
 * @property {string} secret - an alphanumeric string
 * @property {Array.<string>} recoveryCodes - an array that should always have only a single string
 *
 * */
export default class MFAService extends Service {
  mfaToken = '';

  enableMFAResponse = {};

  reset() {
    this.mfaToken = '';
    this.enableMFAResponse = {};
  }
}
