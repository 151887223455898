import Route from '@ember/routing/route';
import permissionGuard from 'core/decorators/permission-guard';
import {
  PREFIX_IAM_SERVICE_PRINCIPALS,
  ACTION_CREATE,
} from 'authz/utils/permission-types/index';
import { inject as service } from '@ember/service';
import fetchUntilEmpty from 'hcp/utils/fetch-until-empty';
import filterBlacklistedRoles from 'manage-access/utils/filter-blacklisted-roles';
import { variation } from 'ember-launch-darkly';

export default class CloudAccessControlServicePrincipalsCreateRoute extends Route {
  @service api;
  @service userContext;
  @service abilities;

  @permissionGuard({
    permission: `${PREFIX_IAM_SERVICE_PRINCIPALS}.${ACTION_CREATE}`,
  })
  async model() {
    const { isProjectContext } = this.modelFor('cloud.access-control');
    const { organization, project } = this.userContext;

    if (isProjectContext) {
      let { policy } =
        await this.api.resourceManager.project.projectServiceGetIamPolicy(
          project.id
        );

      let projectRoles = [];
      if (
        this.abilities.can('list roles') &&
        variation('hcp-ui-fine-grained-roles')
      ) {
        const resourceName = `project/${project.id}`;
        const fetchAllRoles = fetchUntilEmpty(
          (...args) =>
            this.api.resourceManager.resources.resourceServiceListRoles(
              ...args
            ),
          'roles'
        );
        ({ roles: projectRoles } = await fetchAllRoles(
          resourceName,
          undefined,
          100
        ));

        const blacklistedRoles = variation(
          'hcp-ui-fine-grained-blacklisted-roles'
        );
        projectRoles = filterBlacklistedRoles(projectRoles, blacklistedRoles);
      }

      return {
        organization,
        project,
        isProjectContext,
        projectRoles,
        policy,
      };
    }

    return {
      organization,
      project,
    };
  }
}
