import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { SystemNotifications } from 'core/utils/constants';

export default class CloudOrgsDetailIndexRoute extends Route {
  @service billing;
  @service abilities;
  @service operation;
  @service router;
  @service userContext;
  @service currentUser;
  @service flashMessages;

  /**
   * The operations start and stop below is necessary because we don't have a
   * project id for polling. This also prevents tests from silently hanging.
   */
  activate() {
    this.billing.stop();
    this.operation.stop();
  }

  deactivate() {
    this.billing.start();
    this.operation.start();
    this.flashMessages.remove(SystemNotifications.ACCESS_RESTRICTED.id);
  }

  async model() {
    let { billing, organization, projects, runningStatement } =
      this.modelFor('cloud.orgs.detail');

    await this.userContext.setProject(null);

    let { organizations, hasNoOrgRole } = this.currentUser;
    if (hasNoOrgRole && projects.length < 1) {
      let accessRestricted = SystemNotifications.ACCESS_RESTRICTED;
      let context = { name: organization.name };
      if (organizations.length > 1) {
        accessRestricted = {
          ...SystemNotifications.ACCESS_RESTRICTED,
          ...{
            onAction: () => {
              this.router.transitionTo('cloud.orgs.list');
            },
          },
        };
      }
      this.flashMessages.systemNotification(accessRestricted, context);
    }

    return {
      billing,
      runningStatement,
      organization,
      organizations,
      projects,
      hasNoOrgRole,
    };
  }
}
