import Service from '@ember/service';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';

export default class ResourcesProjectService extends Service {
  @service api;
  @service abilities;
  @service userContext;
  @service flashMessages;
  @service intl;

  // This needs to stay a lowercase string to match with the ember ability type.
  type = 'project';

  get orgId() {
    return this.userContext.organization.id;
  }

  get projects() {
    return this.userContext.projects;
  }

  edit = {
    route: 'cloud.orgs.detail.projects.detail.edit',
  };

  delete = {
    task: this.deleteProject,
  };

  @task
  *deleteProject(projectId) {
    yield this.api.resourceManager.project.projectServiceDelete(projectId);
  }
}
