/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { variation } from 'ember-launch-darkly';

/**
 *
 * `HcpMarketingProducts` renders the HDS flight icon and label for each HCP product defined in the hcpProducts object below.
 *
 *
 * ```
 * <HcpMarketing::Products />
 * ```
 *
 * @class HcpMarketingProducts
 *
 */
export default class HcpMarketingProducts extends Component {
  get hcpProducts() {
    const products = [
      {
        icon: 'packer',
        label: 'nav.project.packer',
      },
      {
        icon: 'terraform',
        label: 'nav.project.terraform.header',
      },
      {
        icon: 'consul',
        label: 'nav.project.consul',
      },
      {
        icon: 'boundary',
        label: 'nav.project.boundary',
      },
      {
        icon: 'vault',
        label: 'nav.project.vault',
      },
      {
        icon: 'waypoint',
        label: 'nav.project.waypoint',
      },
    ];

    const rebrandedProducts = [
      // TODO: [HCPIE-1165] Delete me once hcp-rebrand-milestone-0 feature flag is permanently enabled.
      //  SEE: https://app.launchdarkly.com/cloud-services/production/features/hcp-rebrand-milestone-0/targeting
      {
        icon: 'terraform',
        label: 'nav.project.terraform.header',
      },
      {
        icon: 'packer',
        label: 'nav.project.packer',
      },
      {
        icon: 'waypoint',
        label: 'nav.project.waypoint',
      },
      {
        icon: 'vault',
        label: 'nav.project.vault',
      },
      {
        icon: 'boundary',
        label: 'nav.project.boundary',
      },
      {
        icon: 'consul',
        label: 'nav.project.consul',
      },
    ];

    if (variation('hcp-rebrand-milestone-0')) {
      return rebrandedProducts;
    }
    return products;
  }
}
