/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';
import IamPolicy from 'core/utils/iam-policy';

/**
 *
 * `PageUsersDetail` renders the details of a user and allows editing some user
 * traits. This component is set for deprecation via HCPF-1399.
 *
 *
 * ```
 * <Page::Users::Detail
 *   @model={{model}}
 * />
 * ```
 *
 * @class PageUsersDetail
 *
 */
/**
 * Contains the `userPrincipal`, `policy`, and 'organization'.
 * @argument model
 * @type {Object}
 */
export default class PageUsersDetailComponent extends Component {
  @service api;
  @service flashMessages;
  @service intl;
  @service router;
  @service currentUser;

  @tracked orgPolicy;
  @tracked policy;
  @tracked pristineRole;
  @tracked returnFocusTo = null;
  @tracked role;
  @tracked showRemoveModal = false;
  @tracked usersToRemove = null;

  @task
  *save(evt) {
    // don't submit the form
    evt.preventDefault();

    let resp;
    let payload;
    if (this.pristineRole == this.role) {
      return;
    }

    let { id } = this.args.model.userPrincipal;

    this.orgPolicy.changeMemberRole(id, this.role);

    payload = {
      policy: {
        ...this.orgPolicy.get(),
      },
    };

    resp = yield this.api.resourceManager.org.organizationServiceSetIamPolicy(
      this.args.model.organization.id,
      payload
    );

    this.policy = resp.policy;
    this.pristineRole = this.role;
    this.orgPolicy = new IamPolicy(this.policy);

    this.flashMessages.success(
      this.intl.t(
        'components.page.access-control.users.detail.form.success.title'
      )
    );

    return this.router.transitionTo('cloud.access-control.users.list');
  }

  @action
  setupForm() {
    let orgPolicy = new IamPolicy(this.args.model.orgPolicy);
    let { id } = this.args.model.userPrincipal;
    let { roleId } = orgPolicy.getMemberById(id);

    this.orgPolicy = orgPolicy;
    this.pristineRole = roleId;
    this.role = roleId;
    this.policy = this.args.model.orgPolicy;
  }

  @action
  confirmRemoveUsers(users, returnFocusTo) {
    this.showRemoveModal = true;
    this.usersToRemove = users;
    this.returnFocusTo = returnFocusTo;
  }

  @action
  onRemoveUsersError({ users }) {
    this.flashMessages.error(
      this.intl.t('components.page.access-control.users.remove.error.title', {
        numUsers: users.length,
      }),
      {
        actionText: this.intl.t(
          'components.page.access-control.users.remove.error.action'
        ),
        content: this.intl.t(
          'components.page.access-control.users.remove.error.content',
          {
            numUsers: users.length,
          }
        ),
        onAction: (close) => {
          window.location.href =
            'https://support.hashicorp.com/hc/en-us/requests/new';
          return close();
        },
      }
    );

    return;
  }

  @action
  onRemoveUsersSuccess({ users }) {
    this.resetRemoveUsers();

    this.flashMessages.success(
      this.intl.t('components.page.access-control.users.remove.success', {
        numUsers: users.length,
      })
    );

    return this.router.transitionTo('cloud.access-control.users.list');
  }

  @action
  resetRemoveUsers() {
    this.showRemoveModal = false;
    this.usersToRemove = null;
    this.returnFocusTo = null;
  }
}
