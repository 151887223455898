/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';

/**
 *
 * `PageOrganizationsDetailProjectsCard` is a card for the organization
 * dashboard that renders project data and a link to the projects list.
 *
 *
 * ```
 * <Page::Organizations::Detail::ProjectsCard
 *   @organization={{this.organization}}
 *   @projectCount={{1}}
 * />
 * ```
 *
 * @class PageOrganizationsDetailProjectsCard
 *
 */

/**
 * The organization in context.
 * @argument organziation
 * @type {Object}
 */

/**
 * The count of projects in this organization.
 * @argument projectCount
 * @type {number}
 */

export default class PageOrganizationsDetailProjectsCardComponent extends Component {
  get projectCount() {
    let { projectCount = 0 } = this.args;
    return projectCount;
  }
}
