import Route from '@ember/routing/route';

export default class CloudAccountSettingsSecurityEnableMFARoute extends Route {
  async model(params) {
    let { refresh: refreshSecurityIndexRoute } = this.modelFor(
      'cloud.account-settings.security.index'
    );

    return {
      params,
      refreshSecurityIndexRoute,
    };
  }

  resetController(controller) {
    controller.confirmOTP = false;
  }
}
