import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { variation } from 'ember-launch-darkly';
import IamPolicy from 'core/utils/iam-policy';
import { capturePreviousRoute } from 'hcp/utils/capture-previous-route';
import {
  PREFIX_IAM_GROUPS,
  ACTION_UPDATE,
} from 'authz/utils/permission-types/index';

import fetchUntilEmpty from 'hcp/utils/fetch-until-empty';
import filterBlacklistedRoles from 'manage-access/utils/filter-blacklisted-roles';
import { VAULT_RADAR_DEVELOPER_ROLE } from 'hcp/utils/constants';

export default class CloudAccessControlGroupsDetailEditRoute extends Route {
  @service router;
  @service api;
  @service userContext;
  @service abilities;

  async beforeModel() {
    const { isProjectContext, groupLevelPermissions } = this.modelFor(
      'cloud.access-control.groups.detail'
    );

    if (isProjectContext) {
      // The project-level route only gets and sets the IAM policy.
      if (
        this.abilities.cannot('get-iam-policy in project') ||
        this.abilities.cannot('set-iam-policy in project')
      ) {
        const error = new Error('ForbiddenError');
        error.name = 'ForbiddenError';
        error.status = 403;
        throw error;
      }
    } else {
      // The organization-level route needs to edit the group via the groupsService.
      if (
        !groupLevelPermissions?.includes(
          `${PREFIX_IAM_GROUPS}.${ACTION_UPDATE}`
        )
      ) {
        const error = new Error('ForbiddenError');
        error.name = 'ForbiddenError';
        error.status = 403;
        throw error;
      }
    }
  }

  async model(params, transition) {
    const { resource_name: resourceName } = this.paramsFor(
      'cloud.access-control.groups.detail'
    );

    const { isProjectContext } = this.modelFor('cloud.access-control');

    if (isProjectContext) {
      const { project } = this.userContext;

      const [{ group }, { policy: rawProjectPolicy }] = await Promise.all([
        this.api.groups.groupsServiceGetGroup(resourceName),
        this.api.resourceManager.project.projectServiceGetIamPolicy(project.id),
      ]);
      const policy = new IamPolicy(rawProjectPolicy);
      const role = policy.getMemberById(group.resourceId);

      let projectRoles = [];
      if (this.abilities.can('list roles')) {
        const resourceName = `project/${project.id}`;
        const fetchAllRoles = fetchUntilEmpty(
          (...args) =>
            this.api.resourceManager.resources.resourceServiceListRoles(
              ...args
            ),
          'roles'
        );
        ({ roles: projectRoles } = await fetchAllRoles(
          resourceName,
          undefined,
          100
        ));

        const whiteListedRoles = [];
        if (variation('hcp-vault-radar-abac-workflow')) {
          whiteListedRoles.push(VAULT_RADAR_DEVELOPER_ROLE);
        }
        const blacklistedRoles = (
          variation('hcp-ui-fine-grained-blacklisted-roles') ?? []
        ).filter((roleId) => {
          return !whiteListedRoles.includes(roleId);
        });

        projectRoles = filterBlacklistedRoles(projectRoles, blacklistedRoles);
      }

      return {
        group,
        policy,
        project,
        role,
        isProjectContext,
        rawProjectPolicy,
        projectRoles,
      };
    } else {
      const { group } =
        await this.api.groups.groupsServiceGetGroup(resourceName);

      return {
        group,
        isProjectContext,
        previousRouteName: capturePreviousRoute(
          transition,
          'cloud.access-control.groups.list'
        ),
      };
    }
  }

  serialize(_, params) {
    // NOTE: We're decoding the v2 `resource_name`, not serializing it ... that's OK.
    return {
      ...params,
      resource_name: decodeURIComponent(params.resource_name),
    };
  }
}
