/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { task } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { DEBUG } from '@glimmer/env';

/**
 *
 * `PageInvitesRevoke` renders the modal for revoking an invitation previously sent to a user.
 *
 *
 * ```
 * <Page::Invites::RevokeModal
 *   @organization={{organization}}
 *   @invites={{invites}}
 * />
 * ```
 *
 * @class PageInvitesRevoke
 *
 */

/**
 * This shows or hides the modal based on truthiness.
 * @argument isActive
 * @type {Boolean}
 */

/**
 * A callback function to be called on a successful revocation of invites.
 * @argument onSuccess
 * @type {String}
 */

/**
 * The organization context of the invites that are being revoked.
 * @argument organization
 * @type {Object}
 */

/**
 * A list of invites to revoke.
 * @argument invites
 * @type {Object}
 */

/**
 * A callback function to be called when the modal is closed.
 * @argument onCancel
 * @type {String}
 */

export default class PageInvitesRevokeModalComponent extends Component {
  @service api;
  @tracked emails = null;
  @service flashMessages;
  @service intl;

  get invitesEmails() {
    let { invites } = this.args;

    if (invites.length > 3) {
      return null;
    }

    return invites
      .map(({ inviteeEmail }, index, self) => {
        return `${
          self.length > 1 && index === self.length - 1 ? 'and ' : ''
        }${inviteeEmail}`;
      })
      .join(', ');
  }

  @task
  *revokeInvites() {
    try {
      let { organization, invites } = this.args;
      let removals = Promise.allSettled(
        invites.map((invite) => {
          return this.api.invitation.invitationsServiceDeleteOrganizationInvitation(
            organization.id,
            invite.id
          );
        })
      );

      let resp = yield removals;
      let rejected = resp.filter((deleteInvitationResp) => {
        return deleteInvitationResp.status === 'rejected';
      });

      if (rejected.length) {
        this.flashMessages.error(
          this.intl.t(
            `components.page.access-control.invites.revoke-modal.toast.error-title`
          ),
          {
            content: this.intl.t(
              `components.page.access-control.invites.revoke-modal.toast.error-body`,
              {
                user: invites[0].inviteeEmail,
                htmlSafe: true,
              }
            ),
          }
        );
        throw rejected;
      }

      this.args.onSuccess({
        resp,
        invites,
      });
    } catch (e) {
      if (DEBUG) {
        //eslint-disable-next-line no-console
        console.error(e);
      }
    }
  }
}
