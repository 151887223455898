import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class CloudAccountSettingsSecurityRoute extends Route {
  @service currentUser;
  @service api;
  @service flashMessages;
  @service intl;
  @service router;

  async beforeModel() {
    let currentUserIdentities = this.currentUser.user.identityTypes;
    let emailPassUser = currentUserIdentities.includes('EMAIL_PASSWORD');

    if (!emailPassUser) {
      this.router.transitionTo('cloud.account-settings.basic-info');
    }
  }
}
