/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';
import { variation } from 'ember-launch-darkly';
import {
  ORGANIZATION_CREATED,
  PROJECT_CREATED,
} from 'core/utils/consts/analytics-events/platform';

/**
 *
 * `PageOrganizationsCreate` renders the organization create form.
 *
 *
 * ```
 * <Page::Organizations::Create
 *  @onSuccess={{this.model.onSuccess}}
    @organizations={{this.model.organizations}}
    @userId={{this.model.userId}}
 * />
 * ```
 *
 * @class PageOrganizationsCreate
 *
 */

/**
 *
 * `onSuccess` is the async callback function for successfully creating an org.
 * @argument onSuccess;
 * @type {function}
 *
 */

/**
 *
 * `organizations` is a list of organizations a user owns.
 * @argument organizations;
 * @type {Array}
 * @required
 *
 */

/**
 *
 * `userId` is the userId of the user viewing this page.
 * @argument userId;
 * @type {string}
 * @required
 *
 */

export default class PageOrganizationsCreateComponent extends Component {
  @service analytics;
  @service api;
  @service billing;
  @service currentUser;
  @service flashMessages;
  @service intl;
  @service router;
  @service userContext;

  @tracked orgName = this.currentUser?.defaultOrgName;
  @tracked country = 'US';

  // TODO: remove CP once FF is removed
  get showCountrySelectField() {
    return !variation('hcp-ui-terraform-organization-create');
  }

  @action updateCountry(event) {
    this.country = event.target.value;
  }

  @action updateOrgName(event) {
    this.orgName = event.target.value;
  }

  @task *save(event) {
    event.preventDefault();

    const { organization = {} } = yield this.createOrg.perform();
    const { project = {} } = yield this.createProject.perform(organization.id);
    const { onSuccess } = this.args;

    yield this.userContext.setOrganization(organization);
    yield this.userContext.setProject(project);
    yield this.createDefaultBillingAccount.perform(
      organization.id,
      project.id,
      this.country
    );

    this.flashMessages.success(
      this.intl.t('components.page.organizations.create.flash.success')
    );

    this.currentUser.organizations = [
      ...this.currentUser.organizations,
      organization,
    ];

    if (onSuccess && typeof onSuccess === 'function') {
      yield onSuccess({ organization, project });
    }
  }

  @task *createOrg() {
    const reqBody = { name: this.orgName };
    const resp =
      yield this.api.resourceManager.org.organizationServiceCreate(reqBody);
    this.analytics.trackEvent(ORGANIZATION_CREATED, {
      payload: reqBody,
      resp,
    });
    return resp;
  }

  @task *createProject(orgId) {
    const reqBody = {
      name: this.currentUser?.defaultProjectName,
      parent: {
        id: orgId,
        type: 'ORGANIZATION',
      },
    };
    const resp =
      yield this.api.resourceManager.project.projectServiceCreate(reqBody);
    this.analytics.trackEvent(PROJECT_CREATED, {
      payload: reqBody,
      resp,
    });
    return resp;
  }

  @task
  *createDefaultBillingAccount(orgId, projectId, country) {
    if (!this.userContext.billing?.billingAccount) {
      let defaultBilling = yield this.billing.createDefaultBillingAccount(
        orgId,
        projectId,
        country
      );

      this.userContext.billing = defaultBilling;
    }
  }
}
