import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class CloudOrgsDetailProjectsDetailSettingsGeneralRoute extends Route {
  @service api;
  @service abilities;

  async model() {
    let { organization } = this.modelFor('cloud.orgs.detail');
    let { project_id } = this.paramsFor('cloud.orgs.detail.projects.detail');
    let { project } =
      await this.api.resourceManager.project.projectServiceGet(project_id);
    let resources;

    if (this.abilities.can('list resources')) {
      ({ resources } =
        await this.api.resourceManager.resources.resourceServiceList(
          'PROJECT',
          project.id
        ));
    }

    return { project, organization, resources };
  }
}
