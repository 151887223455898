/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';

/**
 *
 * Displays the list of projects for an organization.
 *
 *
 * ```
 * <Page::Projects::List
 *   @organization={{this.model.organization}}
 *   @projects={{array project1 project2}}
 * />
 * ```
 *
 * @class PageProjectsList
 *
 */

/**
 * The current organization.
 * @argument organization
 * @type {Object}
 */

/**
 * The current organization's projects list.
 * @argument projects
 * @type {Array}
 */

export default class PageProjectsListComponent extends Component {
  get paginationQueryFunction() {
    return (page) => {
      if (page === 'next') {
        return {
          nextPageToken: this.args.pagination.nextPageToken,
          previousPageToken: undefined,
        };
      } else {
        return {
          previousPageToken: this.args.pagination.previousPageToken,
          nextPageToken: undefined,
        };
      }
    };
  }
}
