import config from 'hcp/config/environment';

/**
 * Redirects to Auth0 Universal Login with MFA-specific login options
 * @param {any} auth0Client - client created in the auth0 authenticator class instance
 * @param {"enable" | "disable"} mfaState - stored mfa flow state
 * @returns {Promise}
 */
export default function auth0MFALoginRedirect(auth0Client, mfaState) {
  auth0Client.loginWithRedirect({
    audience: `https://${config.APP.auth0.mfa_aud}/mfa/`,
    redirect_uri: `${window.location.origin}/login/callback?mfa=${mfaState}`,
    scope: 'enroll read:authenticators remove:authenticators',
    prompt: 'login',
    connection: 'Username-Password-Authentication',
  });

  return new Promise(() => {});
}
