import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { getOwner } from '@ember/owner';
import { DEBUG } from '@glimmer/env';
import * as Sentry from '@sentry/ember';
import config from 'hcp/config/environment';

export default class CallbackRoute extends Route {
  @service config;
  @service session;
  @service api;
  @service flashMessages;
  @service intl;
  @service mfa;
  @service router;

  queryParams = {
    code: {
      replace: true,
    },
    state: {
      replace: true,
    },
    error: {
      replace: true,
    },
    mfa: {
      replace: true,
    },
    product: {
      replace: true,
    },
  };

  get auth0Client() {
    return getOwner(this).lookup('authenticator:auth0').client;
  }

  async beforeModel() {
    let { code, state, error, mfa } = this.paramsFor(this.routeName);

    try {
      // first check if we're doing MFA things
      if (mfa) {
        // we need to be authenticated to make the enable or disable calls
        await this.session.authenticate('authenticator:cloud-idp', {
          isSilentAuth: true,
        });
        let mfaToken = await this.generateMFAToken();

        if (mfa === 'disable') {
          await this.disableMFA(mfaToken);
          return;
        }
        if (mfa === 'enable') {
          this.mfa.mfaToken = mfaToken;
          return this.router.transitionTo(
            'cloud.account-settings.security.index.mfa',
            {
              queryParams: { confirmOTP: true },
            }
          );
        }
        //TODO: Follow up—decide if we should redirect to homepage instead of Security Index if an invalid mfaState is passed as a param
        return this.redirectToSecurityIndexRoute();
      }
    } catch (callbackMfaException) {
      Sentry.withScope(function (scope) {
        scope.setTag('callback', 'mfa');
        scope.setTag('mfa', `${mfa}`);
        Sentry.captureException(callbackMfaException);
      });

      throw callbackMfaException; // NOTE: Rethrow to avoid changing existing behaviour
    }

    // if not doing MFA, see if we're logged in
    this.session.prohibitAuthentication(config.APP.homeRoute);

    if (error) {
      return this.router.transitionTo(config.APP.loginRoute, {
        queryParams: { error },
      });
    }
    // finally if there's no error and we're not logged in, we want to authenticate our session
    try {
      await this.session.authenticate(
        `authenticator:${this.config.authenticatorType}`,
        {
          isCallback: true,
        }
      );
    } catch (e) {
      Sentry.captureException(e);
    }

    if (!code || !state || !this.session.isAuthenticated) {
      return this.router.transitionTo(config.APP.loginRoute);
    }
  }

  async generateMFAToken() {
    let client = await this.auth0Client;

    try {
      let mfaToken = await client.getTokenSilently({
        audience: `https://${config.APP.auth0.mfa_aud}/mfa/`,
        redirect_uri: `${window.location.origin}/account-settings/security`,
        scope: 'enroll read:authenticators remove:authenticators',
      });

      return mfaToken;
    } catch (response) {
      let errorMessage = 'MFA Token Generation Error';

      if (response.json) {
        let error = await response.json();
        errorMessage = error.message;
      }

      Sentry.captureException(errorMessage);
      this.handleMFATokenGenerationError();
    }
  }

  handleMFATokenGenerationError() {
    this.redirectToSecurityIndexRoute();
    this.flashMessages.error(
      this.intl.t('components.page.account-settings.security.mfa.tokenError')
    );
  }

  redirectToSecurityIndexRoute() {
    return this.router.transitionTo(
      'cloud.account-settings.security.index.index'
    );
  }

  async disableMFA(mfaToken) {
    try {
      await this.api.profile.profileServiceDisableMFA({ mfaToken });
      this.redirectToSecurityIndexRoute();
      this.flashMessages.success(
        this.intl.t(
          'components.page.account-settings.security.mfaDisable.flashMessage.success'
        )
      );
    } catch (e) {
      if (DEBUG) {
        //eslint-disable-next-line no-console
        console.error(e);
      }
      Sentry.captureException(e);

      this.redirectToSecurityIndexRoute();
      this.flashMessages.error(
        this.intl.t(
          'components.page.account-settings.security.mfaDisable.flashMessage.error'
        )
      );
    }
  }

  resetController(controller) {
    controller.error = '';
    controller.code = '';
    controller.state = '';
    controller.mfa = '';
  }
}
