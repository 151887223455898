/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

/**
 *
 * `PageOrganizationsDetailBillingCard` is a card for the organization
 * dashboard that renders billing data and a link to the billing page.
 *
 *
 * ```
 * <Page::Organizations::Detail::BillingCard
 *   @testArg={{foo}}
 * />
 * ```
 *
 * @class PageOrganizationsDetailBillingCard
 *
 */

/**
 * The organization in context.
 * @argument organziation
 * @type {Object}
 */

/**
 * A billing model.
 * @argument billing
 * @type {number}
 */

/**
 * The running statement for billing.
 * @argument runningStatement
 * @type {object}
 */

export default class PageOrganizationsDetailBillingCardComponent extends Component {
  @service systemStatus;

  get statusPageUrl() {
    return this.systemStatus.statuspageUrl;
  }

  get isSameDay() {
    const { billingPeriodStart, billingPeriodEnd } = this.args.runningStatement;

    return (
      billingPeriodStart.getMonth() === billingPeriodEnd.getMonth() &&
      billingPeriodStart.getDate() === billingPeriodEnd.getDate()
    );
  }
}
