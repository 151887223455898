/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';
import { action } from '@ember/object';
/**
 *
 * `IamGroupsProjectFormEditRoleV2` encapsulates the form used to edit a groups's role at the project level.
 *
 * ```
 *    <Iam::Groups::Project::Form::EditRoleV2
 *        @role={{@role}}
 *        @project={{@project}}
 *        @policy={{@rawProjectPolicy}}
 *        @group={{@group}}
 *        @projectRoles={{@projectRoles}}
 *        @handleSubmit={{this.handleSubmitV2}}
 *        as |F|
 *      >
 *        <StickyFooter>
 *          <Hds::ButtonSet>
 *            <F.SubmitButton form={{formId}} type="submit" />
 *            <F.CancelButton />
 *          </Hds::ButtonSet>
 *        </StickyFooter>
 *      </Iam::Groups::Project::Form::EditRoleV2>
 * ```
 *
 * @class IamGroupsProjectFormEditRoleV2
 *
 */

export default class IamGroupsProjectFormEditRoleV2Component extends Component {
  @tracked editedPolicy = this.args.policy;

  @action
  onPolicyChange({ policy }) {
    this.editedPolicy = policy;
  }

  @task
  *submit(event) {
    event.preventDefault();

    yield this.args.handleSubmit.perform(this.editedPolicy);
  }
}
