import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { DEBUG } from '@glimmer/env';
import { variation } from 'ember-launch-darkly';

import IamPolicy from 'core/utils/iam-policy';
import {
  TYPE_ORGANIZATION,
  TYPE_PROJECT,
} from 'common/utils/cloud-resource-types';
import fetchUntilEmpty from 'hcp/utils/fetch-until-empty';
import filterBlacklistedRoles from 'manage-access/utils/filter-blacklisted-roles';
import findRoleLocationsFromPolicies from 'hcp/utils/iam/find-role-locations-from-policies';

export default class CloudAccessControlUsersDetailRoute extends Route {
  @service api;
  @service abilities;
  @service userContext;
  @service router;

  async model({ user_id }) {
    const { isProjectContext } = this.modelFor('cloud.access-control');
    const policies = [];
    const { organization, project } = this.userContext;

    let orgPolicy;
    let projectPolicy;
    let scimEnabled = false;

    if (variation('hcp-identity-scim-enabled')) {
      try {
        ({ isEnabled: scimEnabled } =
          await this.api.scim.scimServiceIsScimEnabled(organization.id));
      } catch (e) {
        if (DEBUG) {
          //eslint-disable-next-line no-console
          console.error(e);
        }
      }
    }

    // Get the ORGANIZATION policy.
    try {
      ({ policy: orgPolicy } =
        await this.api.resourceManager.org.organizationServiceGetIamPolicy(
          organization.id
        ));

      policies.push({
        link: {
          id: organization.id,
          name: organization.name,
          type: TYPE_ORGANIZATION,
        },
        policy: orgPolicy,
      });

      // HCPF-1444: Decide how to push project policy roles here. Currently this
      // cannot be supported because of a potentially large and paginated project
      // list.
      // if (variation('hcp-ui-no-role-enabled')) {
      //   // Get all of the project policies attached to this organization. The
      //   // list of projects should already be attached to the UserContext object.
      //   const { projects } = this?.userContext;

      //   for (let project of projects) {
      //     const { policy: projectPolicy } =
      //       await this.api.resourceManager.project.projectServiceGetIamPolicy(
      //         project.id
      //       );

      //     policies.push({
      //       link: {
      //         id: project.id,
      //         name: project.name,
      //         type: TYPE_PROJECT,
      //       },
      //       policy: new IamPolicy(projectPolicy),
      //     });
      //   }
      // }
    } catch (e) {
      if (DEBUG) {
        //eslint-disable-next-line no-console
        console.error(e);
      }
    }

    if (isProjectContext) {
      try {
        // We have to wrap this in a try/catch because the call may fail based on
        // the user's RBAC role. If the call fails then we redirect them back to the list.
        ({ policy: projectPolicy } =
          await this.api.resourceManager.project.projectServiceGetIamPolicy(
            project.id
          ));

        policies.push({
          link: {
            id: project.id,
            name: project.name,
            type: TYPE_PROJECT,
          },
          policy: projectPolicy,
        });
      } catch (e) {
        if (DEBUG) {
          //eslint-disable-next-line no-console
          console.error(e);
        }

        this.router.transitionTo('cloud.access-control.users.list');
      }
    }

    let projectRoles = [];
    if (this.abilities.can('list roles') && isProjectContext) {
      const resourceName = `project/${project.id}`;
      const fetchAllRoles = fetchUntilEmpty(
        (...args) =>
          this.api.resourceManager.resources.resourceServiceListRoles(...args),
        'roles'
      );
      ({ roles: projectRoles } = await fetchAllRoles(
        resourceName,
        undefined,
        100
      ));

      const blacklistedRoles = variation(
        'hcp-ui-fine-grained-blacklisted-roles'
      );
      projectRoles = filterBlacklistedRoles(projectRoles, blacklistedRoles);
    }

    let userPrincipal;
    userPrincipal =
      await this.api.iam.iamServiceGetUserPrincipalByIdInOrganization(
        organization.id,
        user_id
      );

    // Use the batch call to get the principal's full data including the
    // list of groupIds. This is retrieved with PRINCIPAL_VIEW_FULL.
    let groupIds = [];

    if (variation('hcp-ui-group-permission-assignments-enabled')) {
      ({
        principals: [{ user: userPrincipal, groupIds = [] }],
      } = await this.api.iam.iamServiceBatchGetPrincipals(
        organization.id,
        [user_id],
        'PRINCIPAL_VIEW_FULL'
      ));
    } else {
      ({ userPrincipal } =
        await this.api.iam.iamServiceGetUserPrincipalByIdInOrganization(
          organization.id,
          user_id
        ));
    }

    // Get the full group list by passing in the groupIds as a filter. We will
    // not need this data (and cannot query it) if they don't have the
    // permissions to.
    let groups = [];

    if (variation('hcp-ui-group-permission-assignments-enabled')) {
      const fetchAllGroups = fetchUntilEmpty(
        (...args) => this.api.groups.groupsServiceListGroups(...args),
        'groups'
      );
      ({ groups } = await fetchAllGroups(
        `organization/${organization.id}`,
        undefined,
        groupIds,
        100
      ));
    }

    return {
      groups,
      isProjectContext,
      organization,
      orgPolicy,
      project,
      projectRoles,
      projectPolicy,
      roleLocations: findRoleLocationsFromPolicies({
        principalIds: [
          user_id,
          ...(variation('hcp-ui-group-permission-assignments-enabled')
            ? groupIds
            : []),
        ],
        groups,
        roles: projectRoles,
        policies,
        userPrincipal,
      }),
      scimEnabled,
      userPrincipal,
    };
  }

  async afterModel({
    isProjectContext,
    userPrincipal,
    orgPolicy,
    projectPolicy,
  }) {
    if (
      isProjectContext &&
      this.abilities.cannot('get project-user', userPrincipal, {
        projectPolicy: projectPolicy ? new IamPolicy(projectPolicy) : undefined,
        orgPolicy: orgPolicy ? new IamPolicy(orgPolicy) : undefined,
      })
    ) {
      const error = new Error('ForbiddenError');
      error.name = 'ForbiddenError';
      error.status = 403;
      throw error;
    }
  }
}
