/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { supportTicketPrefillHref } from 'core/utils/support-ticket';
import { ACTIVE_RESOURCES_ORIGIN_PROJECT_DELETE } from 'hcp/utils/constants';

/**
 *
 * Displays the Project's active resources using a table.
 *
 *
 * ```
 * <Page::Projects::ActiveResources
 *   @model={{this.model}}
 * />
 * ```
 *
 * @class PageProjectsActiveResources
 *
 */

export default class PageProjectsActiveResourcesComponent extends Component {
  /**
   * A object contaning a pagination and resources key
   * @argument model
   * @type {Object}
   */

  get resources() {
    let { resources } = this.args.model;
    return resources.map((resource) => ({
      ...resource.link,
      geo: resource.resourceV2?.geo,
    }));
  }

  get transitionedFromProjectDelete() {
    const { activeResourcesListOrigin } = this.args.model;
    return activeResourcesListOrigin === ACTIVE_RESOURCES_ORIGIN_PROJECT_DELETE;
  }

  get supportTicketHref() {
    return supportTicketPrefillHref(this.userContext);
  }
}
