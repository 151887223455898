/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { getOwner } from '@ember/owner';
import { action } from '@ember/object';
import { task } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import auth0MFALoginRedirect from 'hcp/utils/auth0-mfa-login-redirect';

/**
 *
 * Renders the the modal in the MFA disable flow.
 *
 * **Clicking the CTA button** fires `redirectForDisableMFAToken` which triggers a login via
 * the Auth0 Universal Login widget while passing the mfa flow ('disable') in `mfaState`, then
 * redirects the user to the security route where an mfa token is generated, the IAM client's
 * `profileServiceDisableMFA` endpoint is called, and the user is redirected back to the Security Index page
 * (see Callback router).
 *
 * ```
 * <Page::AccountSettings::Security::Mfa::Disable />
 * ```
 *
 */
export default class PageAccountSettingsDisableMFAComponent extends Component {
  @service router;

  authenticator = getOwner(this).lookup('authenticator:auth0');

  @tracked allowDisable = false;

  @task
  *redirectForDisableMFAToken() {
    let client = yield this.authenticator.client;
    auth0MFALoginRedirect(client, 'disable');
  }

  @action
  resetModal() {
    // prevents test failure
    if (this.isDestroyed || this.isDestroying) return;
    this.router.transitionTo('cloud.account-settings.security.index.index');
  }
}
