/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { task } from 'ember-concurrency';
import { inject as service } from '@ember/service';

import { HashicorpCloudResourcemanagerPolicyBindingMemberType } from '@clients/cloud-resource-manager';

import PolicyManager from 'manage-access/utils/policy-manager';

/**
 *
 * `IamGroupsProjectFormSelectRoleV2Component` encapsulates the form used to edit a groups's role at the project level.
 *
 * ```
 * <Iam::Groups::Project::Form::SelectGroupRoleV2
 *   @groups={{@groups}}
 *   @projectRoles={{@projectRoles}}
 *   @policy={{@policy}}
 *   @project={{@project}}
 *   @groupIdToRoleIdMappings={{this.groupIdToRoleIdMappings}}
 *   @handleSubmit={{this.handleSelectGroupRoleSubmitV2}}
 * />
 * ```
 *
 * @class IamGroupsProjectFormSelectRoleV2Component
 *
 */

export default class IamGroupsProjectFormSelectRoleV2Component extends Component {
  @service api;
  @service router;

  @tracked editedPolicy;

  knownGroupIds = Object.keys(this.args?.groupIdToRoleIdMappings ?? {});

  @action
  didInsert() {
    const { policy } = this.args;
    this.setEditedPolicy(policy);
  }

  @action
  didUpdateGroupIdToRoleIdMappings() {
    const { groupIdToRoleIdMappings = {} } = this.args;
    const newKnownGroupIds = Object.keys(groupIdToRoleIdMappings);
    const removedGroupIds = this.knownGroupIds.filter((knownGroupId) => {
      return !newKnownGroupIds.includes(knownGroupId);
    });
    const EditedPolicy = new PolicyManager({ policy: this.editedPolicy });

    for (let groupId of removedGroupIds) {
      const member = {
        memberId: groupId,
        memberType: HashicorpCloudResourcemanagerPolicyBindingMemberType.GROUP,
      };
      const { roleIds = [] } = EditedPolicy.getRolesByMember(member) ?? {};

      for (let roleId of roleIds) {
        EditedPolicy.removeMemberFromRole(member, [roleId]);
      }
    }

    this.knownGroupIds = newKnownGroupIds;
    this.setEditedPolicy(EditedPolicy.getRawPolicy());
  }

  setEditedPolicy(policy) {
    const { groupIdToRoleIdMappings = {} } = this.args;

    const DefaultPolicy = new PolicyManager({ policy });

    for (let [memberId, roleId] of Object.entries(groupIdToRoleIdMappings)) {
      const member = {
        memberId: memberId,
        memberType: HashicorpCloudResourcemanagerPolicyBindingMemberType.GROUP,
      };
      const { roleIds = [] } = DefaultPolicy.getRolesByMember(member) ?? {};

      if (!roleIds.length) {
        DefaultPolicy.addMemberToRole(member, [roleId]);
      }
    }

    this.editedPolicy = DefaultPolicy.getRawPolicy();
  }

  @action
  onPolicyChange({ policy }) {
    this.editedPolicy = policy;
  }

  @action
  removeGroup(resourceId) {
    const { removeGroup } = this.args;

    if (removeGroup && typeof removeGroup === 'function') {
      removeGroup(resourceId);
    }
  }

  @task *submit(event) {
    event.preventDefault();

    yield this.args.handleSubmit.perform(this.editedPolicy);
  }
}
