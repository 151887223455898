import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class CloudAccountSettingsSecurityMFARoute extends Route {
  @service currentUser;
  @service router;

  async beforeModel() {
    let emailPassUser =
      this.currentUser.user.identityTypes.includes('EMAIL_PASSWORD');

    if (!emailPassUser) {
      this.router.transitionTo('cloud.account-settings.basic-info');
    }
  }

  async model() {
    let { mfaStatus } = this.modelFor('cloud.account-settings.security.index');

    return mfaStatus;
  }

  async afterModel(mfaStatus, transition) {
    let mfaIsEnabled = mfaStatus.status === 'ENABLED';

    if (
      mfaIsEnabled &&
      transition.targetName ===
        'cloud.account-settings.security.index.mfa.enable'
    ) {
      this.router.transitionTo('cloud.account-settings.security.index.index');
    }
  }
}
