import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class CloudOrgsDetailRoute extends Route {
  @service api;
  @service billing;
  @service abilities;
  @service router;
  @service userContext;

  async model({ org_id }) {
    let { organization } =
      await this.api.resourceManager.org.organizationServiceGet(org_id);
    await this.userContext.setOrganization(organization);

    // Setting an organization refreshes the list of projects in userContext.
    let projects = this.userContext.projects;

    // This call is using the first project in the list of projects to retrieve
    // a billing account id. At the time of this comment, all projects will
    // return the same billing account id because there is only one billing
    // account per organization. All project IDs will return the same billing
    // account id so any project id can be used. We only need to call getBilling if the userContext
    // for billing is not already set.
    if (
      this.abilities.can(`view billing-account`) &&
      organization.id &&
      projects &&
      projects.length &&
      !this.userContext.billing
    ) {
      await this.billing
        .getBilling(organization.id, projects[0].id)
        .then((billing) => {
          this.userContext.billing = billing;
        });
    }

    // The running statement is required to populate the usage on the billing summary card.
    let runningStatement;
    if (
      this.abilities.can(`view billing-account`) &&
      organization.id &&
      this.userContext.billing?.billingAccount
    ) {
      runningStatement =
        await this.api.billing.statement.statementServiceGetRunningStatement(
          organization.id,
          this.userContext.billing?.billingAccount?.id
        );
    }

    // If sSOManagementServiceGetSSOType returns Not Found, then SSO is not enabled; otherwise, we can assume enabled.
    let ssoEnabled = false;
    try {
      let ssoType = await this.api.sso.sSOManagementServiceGetSSOType(
        organization.id
      );
      if (ssoType !== null) {
        ssoEnabled = true;
      }
    } catch (err) {
      // 404 is returned if SSOType is of type 'None'
      if (err.status === 404) {
        ssoEnabled = false;
      }
    }

    this.userContext.setSsoEnabled(ssoEnabled);

    return { organization, projects, ssoEnabled, ...runningStatement };
  }
}
