/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { getOwner } from '@ember/owner';

/**
 *
 * `PageInvitesList` lists the HCP Invites.
 *
 *
 * ```
 * <Page::Invites::List @model={{model}} />
 * ```
 *
 * @class PageInvitesList
 *
 */

/**
 * `model` has the list of HCP organization invites and inviters.
 * @argument model
 * @type {Object}
 */
export default class PageInvitesListComponent extends Component {
  @service currentUser;
  @service flashMessages;
  @service intl;
  @service router;

  @tracked showRevokeModal = false;
  @tracked inviterMap = {};
  @tracked invitesToRevoke = null;

  @action
  buildInviterMap() {
    let { userPrincipals = [] } = this.args.model;

    this.inviterMap = userPrincipals.reduce((map, user) => {
      map[user.id] = user;
      return map;
    }, {});

    return this;
  }

  @action
  confirmRevokeInvites(invites) {
    this.showRevokeModal = true;
    this.invitesToRevoke = invites;
  }

  @action
  onRevokeInvitesSuccess({ invites }) {
    this.resetRevokeInvites();

    // We should use the router service for this once this PR is merged.
    // via: https://github.com/emberjs/rfcs/issues/592
    let route = getOwner(this).lookup(`route:${this.router.currentRouteName}`);

    this.flashMessages.success(
      this.intl.t(
        `components.page.access-control.invites.revoke-modal.toast.success-title`
      ),
      {
        content: this.intl.t(
          `components.page.access-control.invites.revoke-modal.toast.success-body`,
          {
            user: invites[0].inviteeEmail,
            htmlSafe: true,
          }
        ),
      }
    );
    return route.refresh();
  }

  @action
  resetRevokeInvites() {
    this.showRevokeModal = false;
    this.invitesToRevoke = null;
  }
}
