/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class PageVaultRadarComponent extends Component {
  @service intl;
  @service analytics;

  @action
  trackWaitlistClick() {
    this.analytics.trackEvent('vault_radar_beta_waitlist_link_click');
  }

  /**
   * @description Returns the qualified qualtrics link to our waitlist form
   * @returns {string}
   */
  get waitlistLink() {
    return this.intl.t(
      'components.page.vault-radar.overview.card.waitlist.href'
    );
  }
}
