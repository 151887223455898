/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { getOwner } from '@ember/owner';
import { supportTicketPrefillHref } from 'core/utils/support-ticket';
import getProjectIamAssignmentTrackingMetadata from 'hcp/utils/iam/get-project-iam-assignment-tracking-metadata';
import { PROJECT_IAM_ASSIGNMENT } from 'core/utils/consts/analytics-events/platform';
import { HashicorpCloudResourcemanagerPolicyBindingMemberType } from '@clients/cloud-resource-manager';

/**
 *
 * `PageGroupsProjectEdit` description here.
 *
 *
 * ```
 * <Page::Groups::Project::Edit
 *   @group={{@model.group}}
 *   @policy={{@model.policy}}
 *   @role={{@model.role}}
 *   @project={{@model.project}}
 * />
 * ```
 *
 * @class PageGroupsProjectEdit
 *
 */

export default class PageGroupsProjectEditComponent extends Component {
  @service api;
  @service flashMessages;
  @service intl;
  @service router;
  @service analytics;

  get supportTicketHref() {
    return supportTicketPrefillHref(this.userContext);
  }

  @task
  *handleSubmit(groupProjectEditRole) {
    const { roleId } = groupProjectEditRole;
    const { group, project, policy } = this.args;

    if (roleId) {
      policy.changeMemberRole(group.resourceId, roleId);
    }

    const route = getOwner(this).lookup('route:cloud.access-control');

    try {
      yield this.api.resourceManager.project.projectServiceSetIamPolicy(
        project.id,
        { policy: roleId ? policy.get() : groupProjectEditRole }
      );

      const groupName = group.displayName;
      const projectName = project.name;
      this.flashMessages.success('Group role in project updated', {
        content: this.intl.t(
          'components.page.groups.project.edit.flash-messages.success',
          { groupName, projectName, htmlSafe: true }
        ),
      });

      if (!roleId) {
        const trackingMetadata = getProjectIamAssignmentTrackingMetadata(
          HashicorpCloudResourcemanagerPolicyBindingMemberType.GROUP,
          'edit',
          groupProjectEditRole,
          this.args.rawProjectPolicy,
          project?.id,
          group?.resourceId
        );

        this.analytics.trackEvent(PROJECT_IAM_ASSIGNMENT, trackingMetadata);
      }

      if (route) {
        // We need to refresh this before transitioning because if this is a
        // child route, the parent route will not call the model hook again when
        // transitioning upward even if data was changed in the child.
        route.refresh();
        this.router.transitionTo('cloud.access-control.groups.list');
      }
    } catch (e) {
      this.flashMessages.error(
        this.intl.t(
          'components.page.groups.project.edit.flash-messages.group-role-update-failed'
        ),
        {
          content: this.intl.t(
            'components.page.groups.project.edit.flash-messages.something-went-wrong'
          ),
          linkIcon: 'help',
          linkIconPosition: 'leading',
          linkText: this.intl.t(
            'components.page.projects.users.edit-assignment.contact-support'
          ),
          linkUrl: this.supportTicketHref,
        }
      );
    }
  }
}
