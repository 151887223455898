/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

/**
 *
 * `PageAccountSettingsEnableMFAComponent` renders the MFA modals for the enable flow.
 *
 * This component is the parent to the its modal child components and is primarily responsible for:
 *   1. Resetting the state of the MFA process
 *   2. Displaying error & success messages based on success/error handling in its child components
 *   3. Tracking the current and next modal step in the MFA enable flow
 *
 * ```
 * <Page::AccountSettings::Security::Mfa @model={{this.model}} />
 * ```
 *
 * @class PageAccountSettingsMFA
 *
 * `@model` object value varies depending on where you are in the MFA enable flow. See individual modal for more info on model contents
 * @argument model;
 * @type {Object};
 *
 */

export default class PageAccountSettingsEnableMFAComponent extends Component {
  @service api;
  @service mfa;
  @service intl;
  @service flashMessages;
  @service router;

  @tracked mode = this.args.model.params.confirmOTP
    ? 'QR Code - Confirm OTP'
    : 'Login Redirect';

  @action
  async setNextEnableMFAModalStep() {
    switch (true) {
      case this.mode === 'QR Code - Confirm OTP':
        this.mode = 'Recovery Code';
        break;
      case this.mode === 'Recovery Code':
        this.resetMFAModal(true);
        break;
    }
  }

  @action
  resetMFAModal(mfaEnrollmentIsSuccessful = false) {
    if (mfaEnrollmentIsSuccessful) {
      // Intentionally setting mode blank to prevent UI blip of initial modal—
      // we only want the security page overlay to be displayed on success
      // when navigating back to the security page
      this.mode = '';
      this.handleMFASuccess();
    }

    this.router.transitionTo('cloud.account-settings.security.index.index');

    // reset the state of the mfa service too
    this.mfa.reset();
    this.args.model.refreshSecurityIndexRoute();
  }

  @action
  handleMFASuccess() {
    return this.flashMessages.success(
      this.intl.t(
        'components.page.account-settings.security.mfaEnable.flashMessage.success'
      )
    );
  }

  /* ***This comment can be merged for now***
   * Intentionally general name for disableMFA use, but for now it returns a single flashMessage
   * TODO: Param - enable/disable state/var
   * TODO: Returned flash message depends on param (that doesn't exist yet)
   */
  @action
  handleMFAError(errorMsg) {
    let error =
      errorMsg ??
      'components.page.account-settings.security.mfaEnable.flashMessage.error';
    return this.flashMessages.error(this.intl.t(error));
  }
}
