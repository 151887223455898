import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class CloudAccountSettingsSecurityIndexRoute extends Route {
  @service currentUser;
  @service api;

  async model() {
    let mfaStatus = await this.api.profile.profileServiceGetMFAStatus();

    return {
      refresh: this.refresh.bind(this),
      mfaStatus,
    };
  }
}
