/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';
import { assert } from '@ember/debug';

class IamGroupProjectEditRoleFormState {
  @tracked roleId = '';
}

/**
 *
 * `IamGroupsProjectFormEditRole` encapsulates the form used to edit a groups's role at the project level.
 *
 * ```
 * <Iam::Groups::Project::Form::EditRole
 *   @role={{@model.role}}
 *   @project={{@model.project}}
 *   @handleSubmit={{this.emberConcurrencyTask}}
 *   as |Form formId|
 *  >
 *    <Form.SubmitButton form={{formId}} type="submit" />
 *    <Form.CancelButton />
 *  </Iam::Groups::Project::Form::EditRole>`);
 * ```
 *
 * @class IamGroupsProjectFormEditRole
 *
 */

export default class IamGroupsProjectFormEditRoleComponent extends Component {
  @tracked formState = new IamGroupProjectEditRoleFormState();

  constructor() {
    super(...arguments);

    assert(
      'Argument @role for <Iam::Groups::Project::Form::EditRole /> must have a non-empty .roleId property ...',
      typeof this.args.role?.roleId === 'string' &&
        this.args.role.roleId.length > 0
    );

    assert(
      'Argument @project for <Iam::Groups::Project::Form::EditRole /> must have non-empty .name and .id properties ...',
      typeof this.args.project?.name === 'string' &&
        this.args.project.name.length > 0 &&
        typeof this.args.project?.id === 'string' &&
        this.args.project.id.length > 0
    );

    assert(
      'Argument @handleSubmit for `<Iam::Groups::Project::Form::EditRole /> must be an Ember concurrency task with a .perform() method ...',
      typeof this.args.handleSubmit?.perform === 'function'
    );

    this.formState.roleId = this.args.role.roleId;
  }

  validateForm(/* formState */) {
    // NOTE: No inline form validation, based on the assumptions that:
    //         1. the group passed in already has a valid role/role ID
    //         2. there's no harm in submitting an update with the same role value
    //       This method stub is just a placeholder until those requirements are validated.
    return;
  }

  @task
  *submit(event) {
    event.preventDefault();

    this.validateForm(this.formState);

    yield this.args.handleSubmit.perform(this.formState);
  }
}
