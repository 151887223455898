/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { task } from 'ember-concurrency';
import { getValue } from '@glimmer/tracking/primitives/cache';
import { invokeHelper } from '@ember/helper';
import { DEBUG } from '@glimmer/env';
import { getOwner } from '@ember/owner';

import { HashicorpCloudResourcemanagerPolicyBindingMemberType } from '@clients/cloud-resource-manager';

import { ROLE_TYPE_PROJECT_NO_ROLE_UI_ONLY } from 'core/helpers/rbac-roles';
import rbacRoles from 'core/helpers/rbac-roles';
import IamPolicy from 'core/utils/iam-policy';
import {
  TYPE_ORGANIZATION,
  TYPE_PROJECT,
} from 'common/utils/cloud-resource-types';
import { supportTicketPrefillHref } from 'core/utils/support-ticket';

/**
 *
 * `PageProjectsUsersEditAssignmentComponent` is renders a project-level user
 * principal form for editing a project policy.
 *
 *
 * ```
 * <Page::Projects::Users::EditAssignment
 *   @organization={{this.model.organization}}
 *   @organizationPolicy={{this.model.orgPolicy}}
 *   @project={{this.model.project}}
 *   @projectPolicy={{this.model.projectPolicy}}
 *   @roles={{this.model.roles}}
 *   @userPrincipal={{this.model.userPrincipal}}
 * />
 * ```
 *
 * @class PageProjectsUsersEditAssignmentComponent
 *
 */

/**
 * The organization.
 * @argument organization
 * @type {Object}
 */
/**
 * The organization policy.
 * @argument organizationPolicy
 * @type {Object}
 */
/**
 * The project.
 * @argument project
 * @type {Object}
 */
/**
 * The project policy.
 * @argument projectPolicy
 * @type {Object}
 */
/**
 * The user principal.
 * @argument userPrincipal
 * @type {Object}
 */
/**
 * This should be the organization role and a project role if applicable.
 * @argument roles
 * @type {Array}
 */

export default class PageProjectsUsersEditAssignmentComponent extends Component {
  @service currentUser;
  @service flashMessages;
  @service intl;
  @service userContext;
  @service('resources.project-user') projectUsers;
  @service router;

  @tracked organizationPolicy;
  @tracked organizationRoleId;
  @tracked projectPolicy;
  @tracked projectRoleId;
  @tracked policy;
  @tracked pristineRoleId;
  @tracked roleIdError = false;

  NO_ROLE_VALUE = ROLE_TYPE_PROJECT_NO_ROLE_UI_ONLY.value;
  TYPE_ORGANIZATION = TYPE_ORGANIZATION;
  TYPE_PROJECT = TYPE_PROJECT;

  @action
  setupForm() {
    const { organizationPolicy, projectPolicy, userPrincipal } = this.args;
    const OrganizationPolicy = organizationPolicy
      ? new IamPolicy(organizationPolicy)
      : undefined;
    const ProjectPolicy = projectPolicy
      ? new IamPolicy(projectPolicy)
      : undefined;
    const { id } = userPrincipal;

    // If this user is not already in this policy, we need to add them before
    // attempting to modify their role. Initially, they will have no role. This
    // checks the `memberId` property because if no member is found, an empty
    // object is returned.
    if (ProjectPolicy && !ProjectPolicy.getMemberById(id).memberId) {
      ProjectPolicy.addMember(
        id,
        HashicorpCloudResourcemanagerPolicyBindingMemberType.USER,
        this.NO_ROLE_VALUE
      );
    }

    let { roleId: organizationRoleId } = OrganizationPolicy
      ? OrganizationPolicy.getMemberById(id)
      : {};
    let { roleId: projectRoleId } = ProjectPolicy
      ? ProjectPolicy.getMemberById(id)
      : {};

    this.organizationPolicy = OrganizationPolicy;
    this.organizationRoleId = organizationRoleId;

    this.pristineRoleId = projectRoleId;

    this.projectPolicy = ProjectPolicy;
    this.projectRoleId = projectRoleId;

    this.policy = projectPolicy;
  }

  get supportTicketHref() {
    return supportTicketPrefillHref(this.userContext);
  }

  @task
  *save(evt) {
    // don't submit the form
    evt.preventDefault();

    if (this.pristineRoleId === this.projectRoleId) {
      this.roleIdError = true;
      return;
    }

    const {
      redirectRoute,
      userPrincipal: { id, fullName, email },
    } = this.args;
    const userName = fullName ?? email;

    // We should use the router service for this once this PR is merged.
    // via: https://github.com/emberjs/rfcs/issues/592
    const container = getOwner(this);
    const route = container.lookup(`route:${redirectRoute}`);
    const isRemovingProjectRole = this.projectRoleId === this.NO_ROLE_VALUE;

    if (isRemovingProjectRole) {
      this.projectPolicy.removeMemberById(id);
    } else {
      this.projectPolicy.changeMemberRole(id, this.projectRoleId);
    }

    try {
      const { policy } = yield this.projectUsers.save.perform(
        this.projectPolicy,
        this.userContext.project
      );

      this.policy = policy;
      this.pristineRole = this.projectRoleId;
      this.projectPolicy = new IamPolicy(this.policy);

      if (isRemovingProjectRole) {
        this.flashMessages.success(
          this.intl.t(
            'components.page.projects.users.edit-assignment.project-role-updated-successfully'
          ),
          {
            content: this.intl.t(
              'components.page.projects.users.edit-assignment.user-no-longer-has-role',
              {
                userName: userName,
                htmlSafe: true,
              }
            ),
          }
        );
      } else {
        const role = getValue(
          invokeHelper(this, rbacRoles, () => {
            return {
              positional: [this.projectRoleId],
              named: {
                active: true,
                type: TYPE_PROJECT,
              },
            };
          })
        );

        const userName = fullName || email;
        this.flashMessages.success(
          this.intl.t(
            'components.page.projects.users.edit-assignment.project-role-updated-successfully'
          ),
          {
            content: this.intl.t(
              'components.page.projects.users.edit-assignment.the-role-for-user-is-now',
              {
                htmlSafe: true,
                roleLabel: role?.label || this.projectRoleId,
                userName: userName,
              }
            ),
          }
        );
      }

      if (route) {
        // We need to refresh this before transitioning because if this is a
        // child route, the parent route will not call the model hook again when
        // transitioning upward even if data was changed in the child.
        route.refresh();
        this.router.transitionTo(`${redirectRoute}`);
      }
    } catch (e) {
      if (DEBUG) {
        // eslint-disable-next-line
        console.error(e);
      }
      this.flashMessages.error(
        this.intl.t(
          'components.page.projects.users.edit-assignment.project-role-update-failed'
        ),
        {
          content: this.intl.t(
            'components.page.projects.users.edit-assignment.something-went-wrong'
          ),
          linkIcon: 'help',
          linkIconPosition: 'leading',
          linkText: this.intl.t(
            'components.page.projects.users.edit-assignment.contact-support'
          ),
          linkUrl: this.supportTicketHref,
        }
      );
    }
  }
}
